import { ref, reactive } from 'vue';

const success = ref(true);
const model = reactive({});
const vhkModel = reactive({});

export const useStore = () => {
	const getLoanAmount = () => {
		if (!success.value) {
			return 0;
		}
		if (model.childrenCount == 0) {
			switch (model.numberOfPlannedChildren) {
				case 1:
					return 15;
				case 2:
					return 30;
				case 3:
					return 50;
				default:
					return 0;
			}
		} else if (model.childrenCount == 1) {
			switch (model.numberOfPlannedChildren) {
				case 1:
					return 30;
				default:
					return 50;
			}
		} else if (model.childrenCount == 2) {
			return 50;
		} else {
			return 0;
		}
	};
	return { success, model, vhkModel, getLoanAmount };
};
