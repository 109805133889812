export const APP_STATES = {
	QUESTION: 'QUESTION',
	SUCCESS: 'SUCCESS',
	FAILURE: 'FAILURE',
	VHK: 'VHK'
};

export const QUESTIONS = [
	{
		questionText: 'Házastársként igényelnék a Csok Plusz hitelt?',
		model: 'isMarried',
		vhkModel: 'házastársak',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'igen' },
			{ key: false, value: 'Nem', vhkValue: 'nem', failure: true }
		]
	},
	{
		questionText: 'A feleség életkora 41 év alatti vagy 41 év feletti, de a hitel igénylésekor legalább 12 hetes várandós lesz?',
		model: 'isEligibleForLoan',
		vhkModel: 'életkor',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'rendben' },
			{ key: false, value: 'Nem', vhkValue: 'nem', failure: true }
		]
	},
	{
		questionText: 'Meglévő gyermekek száma',
		model: 'childrenCount',
		vhkModel: 'meglévő gyerekszám',
		answers: [
			{ key: 0, value: '0', vhkValue: '0' },
			{ key: 1, value: '1', vhkValue: '1' },
			{ key: 2, value: '2 vagy több', vhkValue: '2 vagy több' }
		]
	},
	{
		questionText: 'Tervez gyermekvállalást?',
		questionDetail: ' A CSOK Plusz hitel igénylésének feltétele legalább 1 gyermek vállalása.',
		model: 'planningToHaveChild',
		vhkModel: 'gyermekvállalás',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'igen' },
			{ key: false, value: 'Nem', vhkValue: 'nem', nextStep: 5, failure: true }
		]
	},
	{
		questionText: 'Vállalt gyermekek száma (amennyiben a hitel felvételekor legalább 12 hetes várandósság igazolható, az 1 gyermek vállalásnak minősül)',
		model: 'numberOfPlannedChildren',
		vhkModel: 'vállalt gyerekszám',
		answers: [
			{ key: 1, value: '1', vhkValue: '1' },
			{ key: 2, value: '2', vhkValue: '2' },
			{ key: 3, value: '3 vagy több', vhkValue: '3 vagy több' }
		]
	},
	{
		questionText: 'Első közös lakást vásárolják meg a hitelből?',
		model: 'isLoanForFirstHome',
		vhkModel: 'első lakás hitelből',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'igen' },
			{ key: false, value: 'Nem', vhkValue: 'nem', nextStep: 7 }
		]
	},
	{
		questionText: 'A megvásárolni kívánt ingatlan értéke, vagy építés esetén a bekerülési költség maximum 80 millió Ft?',
		model: 'isPropertyValueUnder80M',
		vhkModel: 'ingatlan max értéke 80 millió',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'igen', nextStep: 'feedback' },
			{ key: false, value: 'Nem', vhkValue: 'nem', failure: true, nextStep: 'feedback' }
		]
	},
	{
		questionText: 'A megvásárolni kívánt ingatlan értéke, vagy építés esetén a bekerülési költség maximum 150 millió Ft?',
		model: 'isPropertyValueUnder150M',
		vhkModel: 'ingatlan max értéke 150 millió',
		answers: [
			{ key: true, value: 'Igen', vhkValue: 'igen' },
			{ key: false, value: 'Nem', vhkValue: 'nem', failure: true }
		]
	}
];
